import React from "react";
import Navbar from "components/Navbar/navbar";
import Intro3It from "components/Intro3It/intro3It";
import Footer from "components/Footer/footer";
import Services2It from "components/Services2It/services2It";
import DarkTheme from "layouts/Dark";
import product from "data/product.json";

const Homepage3 = () => {
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);

  React.useEffect(() => {
    var navbar = navbarRef.current;

    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });
  }, [navbarRef]);

  return (
    <DarkTheme>
      <Navbar nr={navbarRef} lr={logoRef} />
      <Intro3It 
        title="Product Solutions"
        img="/img/slid/product.jpg"
        imgAlt="Product Solutions"
        desc="Solutions that run in adjacency to the current technology ecosystem and government initiatives and which makes a resounding impact to the operational efficiencies of organization and wellness of the organizations talents. 
        "
        links={product.title} 
      />
      <Services2It style="4item" contentdata={product.enterprise} />
      <Footer hideBGCOLOR />
    </DarkTheme>
  );
};

export const Head = () => {
  return (
    <>
      <title>Elfonze Technologies - Product Development & Engineering</title>
      <meta key="description" name="description" 
      title="Oracle MPS, Healthcare, Logistics Tools & Talent Management Solutions | Elfonze"
      content="Transform workflows with Oracle-based production scheduling, unified geriatric healthcare platforms, B2B supply-demand talent solutions, and integrated logistics. Elfonze drives efficiency and innovation." />
    </>
  )
}

export default Homepage3;
